
<template>
    <div>
        <div class="flex flex-col lg:grid mt-5 h-full lg:grid-cols-2 lg:gap-4">
            <div class="h-full w-full">
                <div class="relative h-full">
                    <swiper
                        class="swiper"
                        ref="carouselSlider"
                        :options="swiperOption"
                        @slideChange="slideChange"
                    >
                        <swiper-slide class="bg-primary"
                            ><img class="w-full" src="images/reinfelder-new.jpg" alt=""
                        /></swiper-slide>
                        <swiper-slide>
                            <img class="w-full" src="images/gyros.png" alt="" srcset="">
                        </swiper-slide>
                        <div
                            class="swiper-button-prev"
                            slot="button-prev"
                        ></div>
                        <div
                            class="swiper-button-next"
                            slot="button-next"
                        ></div>
                    </swiper>
                </div>
            </div>
            <div class="w-auto flex justify-center">
                <div class="md:h-1/2 md:w-3/5">
                    <div class="bg-primary w-full rounded p-6 shadow-lg">
                        <div class="text-dark-header text-xl font-extrabold">
                            {{ recipe.title }}
                        </div>
                        <div class="mt-5 text-sm font-light leading-6" v-html="recipe.recipe">
                        </div>
                        <div class="mt-5">
                            <div class="flex items-center mb-5" v-if="recipe.vegetarian">
                                <img class="h-12 w-12 mr-4" src="/images/vegetarian.svg" alt="" srcset="">
                                <span class="text-dark">Vegetarisch</span>
                            </div>
                            <div class="flex items-center">
                                <img class="h-12 w-12 mr-4" src="/images/duration.svg" alt="" srcset="">
                                <span class="text-dark">{{ recipe.duration }} Min. Zubereitungszeit</span>
                            </div>
                            <div class="mt-5">
                        <a class="font-medium block bg-white bg-opacity-20 px-4 py-2 border-2 text-sm m-auto text-dark text-center bg-transparent rounded hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:shadow-outline"
                            href="#order">Buch online bestellen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    name: "swiper-example-navigation",
    title: "Navigation",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOption: {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
            recipes: [
                {
                    title: "Reinfelder Pfanne à la Jens",
                    recipe: `
                            200 g Rinderhackfleisch<br>
                            1 kl. Dose Texasbohnen 1 Paprika (grün)<br>
                            1 kl. Dose Champignons<br>
                            1 kl. Dose geschälte Tomaten 1 kl. Strauch Cherrytomaten 1 Chilischote<br>
                            1 kl. Knoblauchzehe<br>
                            1 kl. Dose Chilibohnen 1 Zwiebel<br>
                            2 Tl. Tomatenmark<br>
                            50 ml Gemüsefond<br>
                            1/2 Tl. Kreuzkümmel<br>
                            1 Tl. Paprikapulver, Pfeffer, Oregano<br>
                    `,
                    vegetarian: false,
                    duration: 20,
                },
                {
                    title: "Kartoffelgyros mit Lammlachs",
                    recipe: `
                            300 g kl. Kartoffeln<br>
                            1/2 rote Paprika<br>
                            1/2 grüne Paprika<br>
                            1 kl. Knoblauchzehe<br>
                            1 kl. Zwiebel<br>
                            25 g Kräuterbutter<br>
                            50 g Schafskäse (Feta)<br>
                            2 El. Olivenöl 1 Lammlachs<br>
                    `,
                    vegetarian: false,
                    duration: 30,
                }
            ],
            recipe: {},
        };
    },
    methods: {
        setup() {
            this.recipe = this.recipes[0];
        },

        slideChange() {
            console.log("slide changed");
            this.recipe = this.recipes[this.$refs.carouselSlider?.$swiper.activeIndex];
        },
    },
    mounted: function() {
        this.setup();
    },
};
</script>
