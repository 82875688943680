import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import video from '../../src/videos/sternkoeche.mp4';

import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

// Auto register vue components
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

new Vue({
    el: '#app',

    data: {
        infomail: 'info@dekra-media.de'
    },
});
